import { isProd, isStaging } from '../utils/environmentUtils';

export const partnerPortal = {
  partnerPortalReleased,
  partnerPortalReportOrder,
};

/**
 * Function to capture the global release of partner portal code
 * @returns return true after Partner Portal formal release
 */
function partnerPortalReleased(environ: SensoryLinkEnvironment): boolean {
  return true;
}

/**
 * Function to capture the global release of partner portal reprot ordering
 * @returns return true after Partner Portal formal release
 */
function partnerPortalReportOrder(environ: SensoryLinkEnvironment): boolean {
  if (isProd(environ)) {
    return false;
  } else if (isStaging(environ)) {
    return true;
  } else {
    return true;
  }
}
