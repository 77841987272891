import {
  UPPER_ALPHABET,
  UPPER_GREEK,
  UPPER_LOWER_ALPHABET,
  UPPER_LOWER_GREEK,
} from 'constants/alphabets';

import { useEffect, useState } from 'react';

import * as d3 from 'd3';

import { useQuery } from '@apollo/client';
import PreferencesQueryByUserProducerID from '@graphql/queries/PreferencesQueryByUserProducerID';
import { MapOptions } from 'components/Report/Maps/MapOptions';
import { LabelType } from 'components/Report/Maps/ReportMarketMap/layers/LabelsLayer';
import { TFunction } from 'i18next';
import { merge } from 'lodash';
import { useSelector } from 'react-redux';
import selectViewerUserId from 'selectors/viewerUserId';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import graphqlClient from '../consumers/graphqlClient';
import { isProd } from '../utils/environmentUtils';

export type ZoomBehavior = 'panzoom' | 'microscope';
export enum ProjectTabs {
  EXECUTIVE_SUMMARY,
  PROJECT_SUMMARY,
  FLAVOR_SUMMARY,
  TEXTURE_SUMMARY,
  OPTIMIZATION_SUMMARY,
  NORMS,
  DATA_DOWNLOAD,
  PROJECT_MAP,
  NOVELTY_ANALYSIS,
  TREND_ANALYSIS,
  CATEGORY_INSIGHTS,
}

export const projectTabOrder = (
  customerPreferences: CustomerPreferences,
): number[] => {
  const customerTabOrder = customerPreferences.projectSummary.tabOrder;
  if (!customerTabOrder || customerTabOrder === 'default') {
    return [
      ProjectTabs.EXECUTIVE_SUMMARY,
      ProjectTabs.PROJECT_SUMMARY,
      ProjectTabs.OPTIMIZATION_SUMMARY,
      ProjectTabs.FLAVOR_SUMMARY,
      ProjectTabs.TEXTURE_SUMMARY,
      ProjectTabs.NORMS,
      ProjectTabs.DATA_DOWNLOAD,
      ProjectTabs.PROJECT_MAP,
      ProjectTabs.NOVELTY_ANALYSIS,
      ProjectTabs.TREND_ANALYSIS,
      ProjectTabs.CATEGORY_INSIGHTS,
    ];
  } else {
    return [
      ProjectTabs.PROJECT_SUMMARY,
      ProjectTabs.EXECUTIVE_SUMMARY,
      ProjectTabs.FLAVOR_SUMMARY,
      ProjectTabs.TEXTURE_SUMMARY,
      ProjectTabs.OPTIMIZATION_SUMMARY,
      ProjectTabs.NORMS,
      ProjectTabs.DATA_DOWNLOAD,
      ProjectTabs.PROJECT_MAP,
      ProjectTabs.NOVELTY_ANALYSIS,
      ProjectTabs.TREND_ANALYSIS,
      ProjectTabs.CATEGORY_INSIGHTS,
    ];
  }
};

export const projectTabNames = (
  t: TFunction,
): { [key in ProjectTabs]: string } => {
  return {
    [ProjectTabs.EXECUTIVE_SUMMARY]: t(
      'projects.projectExecutiveSummary.title',
    ),
    [ProjectTabs.PROJECT_SUMMARY]: t('projects.projectSummary.title'),
    [ProjectTabs.FLAVOR_SUMMARY]: t('projects.projectFlavorSummary.title'),
    [ProjectTabs.TEXTURE_SUMMARY]: t('projects.projectTextureSummary.title'),
    [ProjectTabs.OPTIMIZATION_SUMMARY]: t(
      'projects.projectOptimizationSummary.title',
    ),
    [ProjectTabs.NORMS]: t('projects.projectNorms.title'),
    [ProjectTabs.DATA_DOWNLOAD]: t('projects.dataDownload.title'),
    [ProjectTabs.PROJECT_MAP]: t('projects.projectMap.title'),
    [ProjectTabs.NOVELTY_ANALYSIS]: t('projects.noveltyAnalysis.title'),
    [ProjectTabs.TREND_ANALYSIS]: t('projects.trendAnalysis.title'),
    [ProjectTabs.CATEGORY_INSIGHTS]: t('projects.categoryInsights.title'),
  };
};

/**
 * Definition of available customer preferences
 */
export interface CustomerPreferences {
  features: {
    // Show norms tab in project summary
    norms: boolean;
    // Show dataDownload tab in project summary
    dataDownload: boolean;
    // Show Map tab in project summary
    map: boolean;
    // Maximum number of reports that can be requested via SensoryLink
    maximum_report_request: number;
    // show trend analysis
    showTrendAnalysis: boolean;
  };
  dataDownload: {
    // Enable / disable order of operations data download
    orderOfOperations: boolean;
  };
  projectSummary: {
    tabOrder: 'default' | 'mapFirst';
  };
  reportSummary: {
    // Show stack rank norms as rows in report summary table
    showStackRankNorms: boolean;
    // Allow the bulk editing of report labels in bulk edit modal
    allowBulkLabelEdit: boolean;
    // Show category norms as rows in report summary table
    stackRankShowCategoryNorms: boolean;
  };
  executiveSummary: {
    showTopThreeOpt: boolean;
    showStackRankDrivers: boolean;
  };
  marketMap: {
    fixedHeatmapZoom: boolean;
    heatMapColors: 'default' | 'bright_green';
    labelFont:
      | 'Arial'
      | 'Montserrat'
      | 'Times New Roman'
      | 'serif'
      | 'monospace';
    labelColor: 'default' | 'pdf';
    labelStroke: 'labelColor' | 'white' | 'clear';
    labelSize: 'default' | 'large';
    labelArrayType:
      | 'shortGreek'
      | 'longGreek'
      | 'shortAlphabet'
      | 'longAlphabet';
    labelFactor: number;
    showLabelFontPicker: boolean;
    showRotationSlider: boolean;
    heatMapCutoff: number;
    heatMapShape: 'hex' | 'rect';
    showOriginalProductName: boolean;
    defaultZoom: number;
    productCutoff: number;
    zoomBehavior: ZoomBehavior;
  };
}

export const defaultPreferences: CustomerPreferences = {
  features: {
    norms: false,
    dataDownload: false,
    map: true,
    maximum_report_request: 3,
    showTrendAnalysis: false,
  },
  dataDownload: {
    orderOfOperations: false,
  },
  projectSummary: {
    tabOrder: 'default',
  },
  reportSummary: {
    showStackRankNorms: false,
    allowBulkLabelEdit: false,
    stackRankShowCategoryNorms: false,
  },
  executiveSummary: {
    showTopThreeOpt: true,
    showStackRankDrivers: false,
  },
  marketMap: {
    fixedHeatmapZoom: false,
    heatMapColors: 'default',
    labelFont: 'serif',
    labelColor: 'default',
    labelStroke: 'clear',
    labelSize: 'default',
    labelArrayType: 'shortAlphabet',
    labelFactor: 1,
    showLabelFontPicker: false,
    showRotationSlider: false,
    heatMapCutoff: 0.98,
    heatMapShape: 'hex',
    showOriginalProductName: false,
    defaultZoom: 1,
    productCutoff: 0.9,
    zoomBehavior: 'panzoom',
  },
};

export interface PreferencesResponse {
  userPreferenceByUserId: {
    userId: number;
    preference: CustomerPreferences;
  };
  producerPreferenceByProducerId: {
    producerId: number;
    preference: CustomerPreferences;
  };
  producerPartnerByProducerId: {
    partnerByPartnerId: {
      partnerPreferenceByPartnerId: {
        partnerId: number;
        preference: CustomerPreferences;
      };
    };
  };
}

export const mergePreferences = (
  defaultPreferences: CustomerPreferences,
  data: PreferencesResponse | any,
): CustomerPreferences => {
  return merge(
    {},
    defaultPreferences,
    data?.producerPartnerByProducerId?.partnerByPartnerId
      ?.partnerPreferenceByPartnerId?.preference,
    data?.producerPreferenceByProducerId?.preference,
    data?.userPreferenceByUserId?.preference,
  );
};

/**
 * Store the functionality differences depending on workspace.
 *
 * @returns the CustomerPreferences object for that workspace
 */
export function useCustomerPreferences(): CustomerPreferences {
  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const [preferences, setPreferences] =
    useState<CustomerPreferences>(defaultPreferences);

  const { loading, error, data } = useQuery<PreferencesResponse>(
    PreferencesQueryByUserProducerID,
    {
      variables: {
        userId: viewerUserId,
        producerId: workspaceId,
      },
    },
  );

  useEffect(() => {
    if (data) {
      setPreferences(mergePreferences(defaultPreferences, data));
    }
  }, [loading, error, data]);

  return preferences;
}

/**
 * For use outside of React Components, such as in sagas
 * @returns The maximum number of report requests wrapped in a promise
 */
export async function maximumReportRequestCount(
  viewerUserId: number,
  workspaceId: number,
): Promise<number> {
  const { data } = await graphqlClient.query<PreferencesResponse>({
    query: PreferencesQueryByUserProducerID,
    variables: {
      userId: viewerUserId,
      producerId: workspaceId,
    },
  });
  return mergePreferences(defaultPreferences, data).features
    .maximum_report_request;
}

/**
 * Thiw will switch the ability to down order of operations csv on/off
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for order of opts download
 */
export function orderOfOptsDownloadEnabled(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.dataDownload.orderOfOperations;
}

/**
 * This will switch the norms tab on/off
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for norms
 */
export function normsEnabled(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.features.norms;
}

/**
 * This will switch the map tab on/off
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for map
 */
export function mapEnabled(customerPreferences: CustomerPreferences): boolean {
  return customerPreferences.features.map;
}

/**
 * This will switch the data download tab on/off
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for data download
 */
export function dataDownloadEnabled(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.features.dataDownload;
}

/**
 * This will switch whether or not the stack rank norms
 * appear in the report summary table
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for showing stack rank norm rows
 */
export function showStackRankNorms(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.reportSummary.showStackRankNorms;
}

/**
 * This will switch whether or not the category norms
 * appear in the report summary table
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for showing category norm rows
 */
export function stackRankShowCategoryNorms(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.reportSummary.stackRankShowCategoryNorms;
}

/**
 * This will enable / disable the ability to bulk edit the product
 * names and labels on a project via the bulk edit modal
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for allowing bulk edit
 */
export function allowBulkLabelEdit(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.reportSummary.allowBulkLabelEdit;
}

/**
 * This will enable / disable the showing of the top three
 * ordre of operation steps for optimizations on the executive
 * summary screen
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for seeing top three card
 */
export function showTopThreeOpt(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.executiveSummary.showTopThreeOpt;
}

export function zoomBehavior(
  customerPreferences: CustomerPreferences,
): ZoomBehavior {
  return customerPreferences?.marketMap?.zoomBehavior ?? 'microscope';
}

/**
 * This will determine what vector length should be printed in the vector tooltips, depending on
 * how clients represent vectors during zoom
 *
 * @param customerPreferences customer preferences object
 * @param vectorLength previous computed vector length
 * @param zoom zoom value of the market map
 * @returns current presented vector length
 */
export function getVectorLength(
  customerPreferences: CustomerPreferences,
  vectorLength: number,
  zoom: number,
): string {
  switch (zoomBehavior(customerPreferences)) {
    case 'panzoom':
      return vectorLength?.toFixed(2);
    case 'microscope':
    default:
      return vectorLength ? (vectorLength / zoom).toFixed(2) : null;
  }
}

/**
 * PQ based D3 scale
 *
 * @param minpq minimum PQ
 * @param meanpq mean PQ
 * @param maxpq max PQ
 * @returns linear d3 scale for PQ
 */
export function pqScale(
  minpq: number,
  meanpq: number,
  maxpq: number,
): d3.ScaleLinear<number, number, never> {
  return d3.scaleLinear<number, number>().domain([minpq, meanpq, maxpq]);
}

/**
 * This will determine the color scale of the heatmap
 *
 * @param customerPreferences customer preferences object
 * @param minpq Minimum value of the PQ scale
 * @param meanpq Mean value of the PQ scale
 * @param maxpq Maximum value of the PQ scale
 * @returns D3 linear color scale
 */
export function heatMapColors(
  customerPreferences: CustomerPreferences,
  heatmapTransparency: number,
  minpq: number,
  meanpq: number,
  maxpq: number,
): d3.ScaleLinear<string, string, never> {
  switch (customerPreferences.marketMap.heatMapColors) {
    case 'bright_green':
      return d3
        .scaleLinear<string, number>()
        .domain([minpq - meanpq, 0, maxpq - meanpq])
        .range(['#faa092', 'lightgrey', '#8afe86'])
        .interpolate(d3.interpolateRgb);
    case 'default':
    default:
      return d3
        .scaleLinear<string, number>()
        .domain([minpq - meanpq, 0, maxpq - meanpq])
        .range([
          `rgba(255,000,000,${heatmapTransparency})`,
          `rgba(211,211,211,${heatmapTransparency})`,
          `rgba(000,128,000,${heatmapTransparency})`,
        ])
        .interpolate(d3.interpolateRgb);
  }
}

/**
 * Return the label font for the Market Map
 *
 * @param customerPreferences customer preferences object
 * @returns returns the font for the Market Map labels
 */
export function getLabelFont(customerPreferences: CustomerPreferences): string {
  return customerPreferences.marketMap.labelFont;
}

/**
 * Return the color scheme for the labels on the market map
 *
 * @param customerPreferences customer preferences object
 * @param labelType type of label
 * @returns color code for the label
 */
export function getLabelColor(
  customerPreferences: CustomerPreferences,
  labelType: LabelType,
): string {
  switch (customerPreferences.marketMap.labelColor) {
    case 'pdf':
      switch (labelType) {
        case LabelType.REF_FLAVOR:
          return '#1f78b4';
        case LabelType.GGVAR:
        case LabelType.CENTER:
        default:
          return '#022950';
      }
    case 'default':
    default:
      switch (labelType) {
        case LabelType.REF_FLAVOR:
          return '#666699';
        case LabelType.GGVAR:
        case LabelType.CENTER:
        default:
          return '#000000';
      }
  }
}

export function getLabelStroke(
  customerPreferences: CustomerPreferences,
  labelType: LabelType,
): string {
  switch (customerPreferences.marketMap.labelStroke) {
    case 'labelColor':
      return getLabelColor(customerPreferences, labelType);
    case 'white':
      return 'rgba(255, 255, 255, 1)';
    case 'clear':
      return 'rgba(255, 255, 255, 0)';
  }
}

/**
 * Return the relative pixel size of the labels on the Market Map
 *
 * @param customerPreferences customer preferences object
 * @param labelType type of label
 * @returns font size for the label
 */
export function getLabelSize(
  customerPreferences: CustomerPreferences,
  labelType: LabelType,
): number {
  switch (customerPreferences.marketMap.labelSize) {
    case 'large':
      switch (labelType) {
        case LabelType.CENTER:
          return 0;
        case LabelType.REF_FLAVOR:
          return 18;
        case LabelType.GGVAR:
          return 24;
        default:
          return 0;
      }
    case 'default':
    default:
      switch (labelType) {
        case LabelType.CENTER:
          return 0;
        case LabelType.REF_FLAVOR:
          return 14;
        case LabelType.GGVAR:
          return 24;
        default:
          return 0;
      }
  }
}

export function showRotationSlider(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.marketMap.showRotationSlider;
}

export function showLabelFontPicker(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.marketMap.showLabelFontPicker;
}

export function heatMapCutoff(
  customerPreferences: CustomerPreferences,
): number {
  return customerPreferences.marketMap.heatMapCutoff;
}

export function productCutoff(
  customerPreferences: CustomerPreferences,
): number {
  return customerPreferences.marketMap.productCutoff;
}

export function heatMapShape(customerPreferences: CustomerPreferences): string {
  return customerPreferences.marketMap.heatMapShape;
}

/**
 * This will determine how the heatmap bins behave on zoom.
 *
 * @param customerPreferences customer preferences object
 * @param zoom zoom value of the market map
 * @returns the binning radius for the hex bins
 */
export function selectHexRadius(
  customerPreferences: CustomerPreferences,
  zoom: number,
): number {
  if (customerPreferences.marketMap.fixedHeatmapZoom) {
    return 12;
  } else {
    return 12 * zoom;
  }
}

/**
 * This will determine how the heatmap bins (RECT) behave on zoom.
 *
 * @param customerPreferences customer preferences object
 * @param zoom zoom value of the market map
 * @returns the binnding edge length of the rect bins
 */
export function selectRectEdge(
  customerPreferences: CustomerPreferences,
  zoom: number,
): number {
  if (customerPreferences.marketMap.fixedHeatmapZoom) {
    return 17;
  } else {
    return 17 * zoom;
  }
}

export function showOriginalProductName(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.marketMap.showOriginalProductName;
}

export function getDefaultZoom(
  customerPreferences: CustomerPreferences,
): number {
  return customerPreferences.marketMap.defaultZoom;
}

/**
 * This will return the default label factor to apply to the Market Map.
 *
 * @param customerPreferences customer preferences object
 * @returns default label factor
 */
export function getLabelFactor(
  customerPreferences: CustomerPreferences,
): number {
  return customerPreferences.marketMap.labelFactor;
}

/**
 * This will return the default lettering scheme for a map
 *
 * @param customerPreferences customer preferences object
 * @returns array of default letting for the map in order
 */
export function getLabelArray(
  customerPreferences: CustomerPreferences,
): string[] {
  switch (customerPreferences.marketMap.labelArrayType) {
    case 'shortGreek':
      return UPPER_GREEK;
    case 'longGreek':
      return UPPER_LOWER_GREEK;
    case 'shortAlphabet':
      return UPPER_ALPHABET;
    case 'longAlphabet':
      return UPPER_LOWER_ALPHABET;
  }
}

export function showLLMOfpDescription(
  customerPreferences: CustomerPreferences,
  environment: SensoryLinkEnvironment,
): boolean {
  return !isProd(environment);
}

/**
 * This will switch whether or not the stack rank drivers
 * appear in the executive summary tab
 *
 * @param customerPreferences customer preferences object
 * @returns boolean for showing stack rank drivers table
 */
export function showStackRankDrivers(
  customerPreferences: CustomerPreferences,
): boolean {
  return customerPreferences.executiveSummary.showStackRankDrivers;
}

/**
 * Get the default map (flavor or texture) options for this customer
 *
 * @param customerPreferences customer preferences object
 * @returns customer's default map options
 */
export function getDefaultMapOptions(
  customerPreferences: CustomerPreferences,
): MapOptions {
  return {
    zoom: getDefaultZoom(customerPreferences),
    labelFactor: getLabelFactor(customerPreferences),
    rotationAngle: 0,
    rotationPristine: true,
    contoursVisible: true,
    vectorsVisible: true,
    productsVisible: true,
    heatmapVisible: true,
    contoursTransparency: 1,
    vectorsTransparency: 1,
    productsTransparency: 1,
    heatmapTransparency: 1,
    tx: 0,
    ty: 0,
  };
}
