import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportTextureCompPrefQuery from '@graphql/queries/ReportTextureCompositionQuery';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';
import TextureClusterStandardError from '@graphql/queries/TextureClusterStandardErrorQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  HighlightableTableRow,
  hightlightedConditionalRowStyles,
} from 'components/ReactDataTable/highlightedRowStyles';
import { materialTableStyles } from 'components/ReactDataTable/materialTableStyles';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, { ProductVersionPq } from 'hooks/useReportSummary';
import DataTable, {
  Selector,
  SortOrder,
  TableColumn,
} from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import {
  getReshapedData,
  getTCNameByIndex,
  getTCStandardErrorByIndex,
  isStandardErrorRequested,
  numberOfClusters,
  selectCellValue,
  sortByPq,
  getTCDisplayName,
} from './utils';
import CSVDownload from '../../../CSVDownload';
import { ProductVersionSet } from '../../ProductVersion';
import { isProductHighlighted } from '../../utils';
import { formatCellValue } from '../textureUtils';

const TC_STANDARD_ERROR = 'TC Standard Error';

// Keep the TC_STANDARD_ERROR row last on any sort
const customSort = (
  rows: TableRow[],
  selector: Selector<TableRow>,
  sortDirection: SortOrder,
) => {
  return rows.sort((rowA, rowB) => {
    // use the selector function to resolve your field names by passing the sort comparitors
    const aField = selector(rowA);
    const bField = selector(rowB);

    if (rowA.productDisplayName === TC_STANDARD_ERROR) return +1;
    if (rowB.productDisplayName === TC_STANDARD_ERROR) return -1;

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return sortDirection === 'desc' ? comparison * -1 : comparison;
  });
};

interface TableRow extends HighlightableTableRow {
  productDisplayName: string;
  pq: string | number;
  [x: string]: string | number | boolean;
}

const getClusterColumns = (
  reportTextureComposition: reports.ReportTextureCompositionResponse,
  highestClusterScoreId: number,
  useTextureClusterName: boolean,
  tcNameData: reports.ReportTextureClusterNamesResponse,
): TableColumn<TableRow>[] =>
  numberOfClusters(reportTextureComposition).map((clusterId, key) => ({
    name: useTextureClusterName ? (
      <div>{getTCNameByIndex(tcNameData, clusterId) ?? 'TC' + clusterId}</div>
    ) : (
      <div>{'TC' + clusterId}</div>
    ),
    selector: (row) => row[`TC${clusterId}`],
    width: '7%',
    compact: true,
    sortable: true,
    right: true,
    conditionalCellStyles: [
      {
        when: (row) => row.productDisplayName === TC_STANDARD_ERROR,
        style: {
          fontSize: 12,
          color: 'black',
        },
      },
      {
        when: (row) =>
          `TC${clusterId}` === `TC${highestClusterScoreId}` &&
          row.productDisplayName !== TC_STANDARD_ERROR,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    cell: (row) => (
      <Tooltip
        title={`The intensity of ${getTCDisplayName(tcNameData, clusterId, useTextureClusterName)} in ${row.productDisplayName} is ${row[`TC${clusterId}`] == '--' ? 'negligible' : row[`TC${clusterId}`]}`}
      >
        <span>{row[`TC${clusterId}`]}</span>
      </Tooltip>
    ),
  }));

const getColumns: (
  reportTextureComposition: reports.ReportTextureCompositionResponse,
  highestClusterScoreId: number,
  useTextureClusterName: boolean,
  tcNameData: reports.ReportTextureClusterNamesResponse,
) => TableColumn<TableRow>[] = (
  reportTextureComposition,
  highestClusterScoreId,
  useTextureClusterName,
  tcNameData,
) => [
  {
    name: 'Product',
    selector: (row) => row.productDisplayName,
    sortable: true,
    conditionalCellStyles: [
      {
        when: (row) => row.productDisplayName === TC_STANDARD_ERROR,
        style: {
          fontSize: 12,
          color: 'black',
          paddingLeft: '30px',
        },
      },
    ],
  },
  {
    id: 'pq',
    name: 'PQ',
    selector: (row) => row.pq,
    width: '7%',
    compact: true,
    sortable: true,
    right: true,
  },
  ...getClusterColumns(
    reportTextureComposition,
    highestClusterScoreId,
    useTextureClusterName,
    tcNameData,
  ),
];

function textureIndicesRow(reportTextureComposition, tcNameData) {
  return {
    Product: '',
    PQ: null,
    ...Object.fromEntries(
      numberOfClusters(reportTextureComposition).map((n) => [
        getTCNameByIndex(tcNameData, n) ?? `TC${n}`,
        `TC${n}`,
      ]),
    ),
  };
}

interface ReportProps {
  projectId?: number;
  reportId: string;
  useTextureClusterName?: boolean;
  highestClusterScoreId?: number;
}

const ReportTextureComposition: React.FC<ReportProps> = (props) => {
  const { projectId, reportId, useTextureClusterName, highestClusterScoreId } =
    props;

  const { t } = useTranslation();
  const customProductNames = useCustomProductNames({ projectId, reportId });
  const theme = useTheme();

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummary,
    productVersionSet,
    productVersionPqs,
  } = useReportSummary(reportId);

  const {
    loading: reportTextureCompositionLoading,
    error: reportTextureCompositionError,
    data: reportTextureComposition,
  } = useQuery<reports.ReportTextureCompositionResponse>(
    ReportTextureCompPrefQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    data: tcNameData,
    loading: tcNameLoading,
    error: tcNameError,
  } = useQuery<reports.ReportTextureClusterNamesResponse>(
    textureClusterNamesQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    data: tcStandardErrorData,
    loading: tcStandardErrorLoading,
    error: tcStandardErrorError,
  } = useQuery<reports.ReportTextureClusterStandardErrorResponse>(
    TextureClusterStandardError,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    reportTextureCompositionLoading ||
    reportTextureCompositionError ||
    tcStandardErrorLoading ||
    tcStandardErrorError ||
    getReshapedData(
      reportTextureComposition,
      productVersionSet,
      productVersionPqs,
      customProductNames,
    ).length === 0
  ) {
    return <div />;
  }

  function getTableData(
    reportTextureComposition: reports.ReportTextureCompositionResponse,
    productVersionSet: ProductVersionSet,
    productVersionPqs: ProductVersionPq[],
    customProductNames: reports.ColorVersionedProductInfo[],
    tcStandardErrorData: reports.ReportTextureClusterStandardErrorResponse,
  ): TableRow[] {
    return getReshapedData(
      reportTextureComposition,
      productVersionSet,
      productVersionPqs,
      customProductNames,
    )
      .map(
        (row, key): TableRow => ({
          productDisplayName: row.name,
          pq: isNaN(row.clusters[0].composition) ? '--' : row.pq,
          isHighlighted: isProductHighlighted({
            productNames: customProductNames,
            productId: row.clusters[0].productByProductId.id,
            version: null,
          }),
          ...Object.fromEntries(
            numberOfClusters(reportTextureComposition)
              .map((clusterIdx) => `TC${clusterIdx}`)
              .map((tcIdx) => [
                tcIdx,
                formatCellValue(selectCellValue(row, tcIdx)),
              ]),
          ),
        }),
      )
      .concat(
        isStandardErrorRequested(tcStandardErrorData)
          ? [
              {
                productDisplayName: TC_STANDARD_ERROR,
                pq: null,
                isHighlighted: false,
                ...Object.fromEntries(
                  numberOfClusters(reportTextureComposition)
                    .map((clusterIdx) => `TC${clusterIdx}`)
                    .map((tcIdx) => [
                      tcIdx,
                      getTCStandardErrorByIndex(
                        tcStandardErrorData,
                        parseInt(tcIdx.substring(2)),
                      ),
                    ]),
                ),
              },
            ]
          : [],
      );
  }

  return (
    <div>
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Typography variant="h5">
              {t('reports.textureComposition.title')}
            </Typography>
            <Tooltip title={t('reports.textureComposition.explainer')}>
              <IconButton size="large">
                <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <CSVDownload
            fileName="Texture_Composition.csv"
            headers={[
              t('reports.textureComposition.product'),
              t('reports.textureComposition.pq'),
            ]
              .concat(
                numberOfClusters(reportTextureComposition).map((clusterId) =>
                  useTextureClusterName
                    ? getTCNameByIndex(tcNameData, clusterId) ??
                      `TC${clusterId}`
                    : `TC${clusterId}`,
                ),
              )
              .map((d) => ({ label: d, key: d }))}
            csvData={[
              textureIndicesRow(reportTextureComposition, tcNameData),
            ].concat(
              getReshapedData(
                reportTextureComposition,
                productVersionSet,
                productVersionPqs,
                customProductNames,
              )
                .sort(sortByPq)
                .map((d) => {
                  const re = {
                    Product: d.name,
                    PQ: d.pq,
                  };
                  numberOfClusters(reportTextureComposition).forEach((n) => {
                    if (useTextureClusterName) {
                      Object.assign(re, {
                        [getTCNameByIndex(tcNameData, n) ?? `TC${n}`]:
                          d.clusters[n - 1].composition,
                      });
                    } else {
                      Object.assign(re, {
                        [`TC${n}`]: d.clusters[n - 1].composition,
                      });
                    }
                  });
                  return re;
                }),
            )}
          />
        </Grid>
      </Grid>
      <DataTable
        columns={getColumns(
          reportTextureComposition,
          highestClusterScoreId,
          useTextureClusterName,
          tcNameData,
        )}
        data={getTableData(
          reportTextureComposition,
          productVersionSet,
          productVersionPqs,
          customProductNames,
          tcStandardErrorData,
        )}
        defaultSortFieldId={'pq'}
        defaultSortAsc={false}
        sortFunction={customSort}
        customStyles={materialTableStyles(theme)}
        noDataComponent={() => <div />}
        conditionalRowStyles={hightlightedConditionalRowStyles}
      />
    </div>
  );
};

export default ReportTextureComposition;
