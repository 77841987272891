import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import selectViewerUserId from '../selectors/viewerUserId';

interface PartnerByPartnerId {
  partnerByPartnerId: {
    id: number;
    name: string;
  };
}

interface ProducerPartnerResults {
  producerById: {
    producerPartnerByProducerId: PartnerByPartnerId;
  };
}

const partnerQuery = gql`
  query UserPartnerQuery($producerId: Int!) {
    producerById(id: $producerId) {
      name
      producerPartnerByProducerId {
        partnerByPartnerId {
          id
          name
        }
      }
    }
  }
`;

const useCurrentPartner = (): number => {
  const [currentPartner, setCurrentPartner] = useState<number>(null);

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const { data, loading, error } = useQuery<ProducerPartnerResults>(
    partnerQuery,
    {
      variables: { producerId },
      skip: !viewerUserId || !producerId,
    },
  );

  useEffect(() => {
    if (!loading && !error && data) {
      const partnerId =
        data.producerById?.producerPartnerByProducerId?.partnerByPartnerId?.id;
      if (partnerId) {
        setCurrentPartner(partnerId);
      }
    }
  }, [data, loading, error]);

  return currentPartner;
};

export default useCurrentPartner;
