import { UPPER_ALPHABET } from 'constants/alphabets';
import { NULL_REPORT_ID } from 'constants/report';

import * as React from 'react';
import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import MultiProjectReportsQuery from '@graphql/queries/MultiProjectReportsQuery';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import MaterialButton from 'components/MaterialButton';
import ReportMarketMap from 'components/Report/Maps/ReportMarketMap';
import ReportTextureMap from 'components/Report/Maps/ReportMarketMap/ReportTextureMap';
import ProductMarketPreference from 'components/Report/ProductMarketPreference';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import ReportSummaryComparison from 'components/Report/ReportSummary/ReportSummaryComparison';
import { getProductName } from 'components/Report/utils';
import { formatReportLabel } from 'containers/Project/utils';
import { multiProjectCustomNames } from 'hooks/useCustomProductNames';
import useWindowSize from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import selectViewerUserId from 'selectors/viewerUserId';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import { useCustomerPreferences } from 'services/customerPreferences';
import { nullCheck } from 'utils/afsUtils';

import styles from './ProjectComparison.module.css';
import LoadingScreen from '../../components/LoadingScreen';
import FlavorDecompositionComparison from '../../components/Report/FlavorDecomposition/FlavorDecompositionComparison/FlavorDecompositionComparison';
import {
  MapOptions,
  getDefaultMapOptions,
} from '../../components/Report/Maps/MapOptions';
import GastrographRadar from '../../components/Report/ReportGastrographRadar/ReportGastrographRadar';
import i18n from '../../i18n';
import { PageHeader } from '../../styles/themeComponents';

export const COL_WIDTH = 480;

export enum ComparisonTabs {
  FLAVOR_PROFILE,
  FLAVOR_DECOMPOSITION,
  PQ_DISTRIBUTION,
  DIVIDER,
  STACK_RANK,
  MARKET_MAP,
  TEXTURE,
}

const WORKSPACE_PROJECTS_QUERY = gql`
  query ProjectsByWorkspace($workspaceId: Int) {
    allProjects(condition: { workspaceId: $workspaceId }) {
      nodes {
        id
        name
      }
    }
  }
`;

const isProductCompareTab = (tabIndex: number) => {
  switch (tabIndex) {
    case ComparisonTabs.STACK_RANK:
    case ComparisonTabs.MARKET_MAP:
    case ComparisonTabs.TEXTURE:
      return false;
    default:
      return true;
  }
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return <div>{value === index && children}</div>;
}

interface ProjectComparisonReportInfo {
  projectId: number;
  reportId: string;
  name: string;
  products: reports.VersionedProductInfo[];
}

interface Props extends RouterProps {
  match: Record<string, any>;
}

const ProjectComparison: React.FC<Props> = (props) => {
  const { match, history: browserHistory } = props;
  const { projectId }: { projectId: string } = match.params;

  /* Optionally passed in values from Report Summary screen */
  const {
    firstReportId,
    firstProductId,
    firstProductVersion,
    secondReportId,
    secondProductId,
    secondProductVersion,
  } = match.params;

  const { t } = useTranslation();
  const customerPreferences = useCustomerPreferences();

  const { width } = useWindowSize();

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  interface ProductToCompareMap extends reports.ProductToCompare {
    projectId: number;
    mapOptions: MapOptions;
  }

  const [selectedProducts, setSelectedProducts] = useState<
    ProductToCompareMap[]
  >([
    {
      projectId: parseInt(projectId),
      reportId: firstReportId ?? '',
      productId: parseInt(firstProductId) ?? null,
      version: nullCheck(firstProductVersion),
      reportProducts: [],
      mapOptions: getDefaultMapOptions(customerPreferences),
    },
    {
      projectId: parseInt(projectId),
      reportId: secondReportId ?? '',
      productId: parseInt(secondProductId) ?? null,
      version: nullCheck(secondProductVersion),
      reportProducts: [],
      mapOptions: getDefaultMapOptions(customerPreferences),
    },
  ]);

  const customProductNames = multiProjectCustomNames(
    selectedProducts.map((p) => p.projectId),
  );

  const { loading, error, data } = useQuery<reports.MultiProjectReport>(
    MultiProjectReportsQuery,
    {
      variables: {
        projectIDs: selectedProducts.map((p) => p.projectId),
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [projectName, setProjectName] = useState<string>('');
  const [projectReports, setProjectReports] = useState<
    ProjectComparisonReportInfo[]
  >([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(
    ComparisonTabs.FLAVOR_PROFILE,
  );
  const [workspaceProjects, setWorkspaceProjects] = useState<
    Array<{ id: number; name: string }>
  >([]);

  const { loading: projectsLoading, error: projectsError } = useQuery(
    WORKSPACE_PROJECTS_QUERY,
    {
      variables: {
        workspaceId: parseInt(producerId),
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => setWorkspaceProjects(data.allProjects.nodes ?? []),
    },
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {
    if (!loading && !error) {
      const reports = data.projects.nodes
        .map((project) =>
          project.projectReportsByProjectId.nodes
            .filter((f) => f.reportJob.passedQa)
            .map((item) => ({
              projectId: project.id,
              reportId: item.reportJob.reportId,
              name: formatReportLabel(
                item.reportJob.projectName,
                item.reportJob.targetGroupName,
                item.reportJob.reportType,
                item.reportJob.startedAt,
              ),
              products: item.reportJob.reportSummaries.nodes.map((item) => {
                const customName =
                  project.customPqProductNamesByProjectId.nodes.find(
                    (f) => f.productId === item.productByProductId.id,
                  );
                return {
                  id: item.productByProductId.id,
                  canonicalName: item.productByProductId.name,
                  name: customName
                    ? customName.productName
                    : item.productByProductId.name,
                  version: item.version,
                };
              }),
            })),
        )
        .flat();
      setProjectReports(reports);
      setProjectName(
        data.projects.nodes.length > 1
          ? 'Multi-Project Comparison'
          : data.projects.nodes[0].name,
      );
      setSelectedProducts((current) =>
        current.map((c, ci) => {
          if (!c.reportId) {
            return c;
          }
          return {
            ...c,
            reportProducts: reports.find((f) => f.reportId === c.reportId)
              .products,
          };
        }),
      );
    }
  }, [loading]);

  if (loading || error || customProductNames.length == 0) {
    return <LoadingScreen />;
  }

  function getProductId(value: string): number {
    if (value.match(/^.*|.*$/)) {
      return parseInt(value.split('|')[0]);
    } else {
      return null;
    }
  }

  function getProductVersion(value: string): string {
    if (value.match(/^.*|.*$/)) {
      return nullCheck(value.split('|')[1]);
    } else {
      return null;
    }
  }

  function hasCompleteSelections(selectedProducts: ProductToCompareMap[]) {
    return (
      selectedProducts.reduce((a, b) => a && !!b.reportId, true) &&
      selectedProducts.reduce((a, b) => a && !!b.productId, true)
    );
  }

  return (
    <Paper className={styles.container} style={{ width: `${width - 320}px` }}>
      <div>
        <div className={styles.header}>
          <IconButton
            onClick={browserHistory.goBack}
            size="small"
            style={{ marginLeft: -26 }}
          >
            <KeyboardBackspaceIcon fontSize="small" />
            <PageHeader>{i18n.t('projects.comparison.back')}</PageHeader>
          </IconButton>
        </div>
        <div className={styles.headerTextContainer}>
          <h4 className={styles.summaryTitle}>{projectName}</h4>
        </div>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <div style={{ width: COL_WIDTH * selectedProducts.length + 90 }}>
          <div className={styles.selectMain}>
            {selectedProducts.map((d, i) => (
              <div
                className={styles.selectViewContainer}
                style={{ width: COL_WIDTH }}
                key={`picker-${i}`}
              >
                {!(projectsLoading || projectsError) && (
                  <div className={styles.selectView}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id={`project-id-${i}-select-label`} shrink>
                        {i18n.t('projects.comparison.selectedProject')}
                      </InputLabel>
                      <Select
                        id={`project-id-${i}-select`}
                        variant="standard"
                        value={d.projectId}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setSelectedProducts((current) =>
                            current.map((c, ci) =>
                              ci == i
                                ? {
                                    projectId: parseInt(event.target.value),
                                    reportId: null,
                                    productId: null,
                                    version: '',
                                    reportProducts: [],
                                    mapOptions:
                                      getDefaultMapOptions(customerPreferences),
                                  }
                                : c,
                            ),
                          );
                        }}
                      >
                        {workspaceProjects.map((project, pi) => (
                          <MenuItem
                            key={`MenuItem-Project-${project.id}-${pi}`}
                            value={project.id}
                          >
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className={styles.selectView}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id={`report-id-${i}-select-label`} shrink>
                      {i18n.t('projects.comparison.selectedReport')}
                    </InputLabel>
                    <Select
                      id={`report-id-${i}-select`}
                      variant="standard"
                      value={d.reportId}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setSelectedProducts((current) =>
                          current.map((c, ci) =>
                            ci == i
                              ? {
                                  projectId: c.projectId,
                                  reportId: event.target.value,
                                  productId: null,
                                  version: '',
                                  reportProducts: projectReports.find(
                                    (f) => f.reportId === event.target.value,
                                  ).products,
                                  mapOptions:
                                    getDefaultMapOptions(customerPreferences),
                                }
                              : c,
                          ),
                        );
                      }}
                    >
                      {projectReports
                        .filter((report) => report.projectId == d.projectId)
                        .map((report) => (
                          <MenuItem
                            key={`MenuItem-${report.reportId}-${i}`}
                            value={report.reportId}
                          >
                            {report.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.selectView}>
                  {d.reportProducts && isProductCompareTab(currentTabIndex) && (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id={`product-id-${i}-select-label`} shrink>
                        {i18n.t('projects.comparison.product')}
                      </InputLabel>
                      <Select
                        id={`product-id-${i}-select`}
                        variant="standard"
                        value={
                          d.productId === null
                            ? ''
                            : new ProductVersion(d.productId, d.version).value
                        }
                        disabled={!d.reportProducts.length}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setSelectedProducts((current) =>
                            current.map((c, ci) =>
                              ci != i
                                ? c
                                : {
                                    ...d,
                                    productId: getProductId(event.target.value),
                                    version: getProductVersion(
                                      event.target.value,
                                    ),
                                  },
                            ),
                          );
                        }}
                      >
                        {d.reportProducts.map((product) => (
                          <MenuItem
                            key={
                              new ProductVersion(product.id, product.version)
                                .value
                            }
                            value={
                              new ProductVersion(product.id, product.version)
                                .value
                            }
                          >
                            {[
                              UPPER_ALPHABET[i],
                              getProductName({
                                productNames: customProductNames,
                                productId: product.id,
                                version: product.version,
                              }),
                            ].join(' - ')}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>
            ))}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                marginTop: '42px',
                marginBottom: '30px',
                width: '64px',
              }}
            >
              {selectedProducts.length < 10 && (
                <MaterialButton
                  variant="outlined"
                  soft
                  onClick={() =>
                    setSelectedProducts((current) =>
                      current.concat([
                        {
                          projectId: parseInt(projectId),
                          reportId: null,
                          productId: null,
                          version: null,
                          reportProducts: [],
                          mapOptions: getDefaultMapOptions(customerPreferences),
                        },
                      ]),
                    )
                  }
                >
                  +
                </MaterialButton>
              )}
              <MaterialButton
                variant="outlined"
                soft
                onClick={() =>
                  setSelectedProducts((current) => current.slice(0, -1))
                }
              >
                -
              </MaterialButton>
            </div>
          </div>
        </div>
        <div style={{ width: COL_WIDTH * selectedProducts.length + 90 }}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                sx={{ width: '100%' }}
              >
                <Tab
                  label={t('projects.comparison.flavorProfileTab')}
                  value={ComparisonTabs.FLAVOR_PROFILE}
                />
                <Tab
                  label={t('projects.comparison.flavorCompositionTab')}
                  value={ComparisonTabs.FLAVOR_DECOMPOSITION}
                  disabled={selectedProducts.length != 2}
                />
                <Tab
                  label={t('projects.comparison.pqDistributionTab')}
                  value={ComparisonTabs.PQ_DISTRIBUTION}
                />
                <Tab
                  label={t('projects.comparison.stackRankTab')}
                  value={ComparisonTabs.STACK_RANK}
                />
                <Tab
                  label={t('projects.comparison.marketMapTab')}
                  value={ComparisonTabs.MARKET_MAP}
                />
                <Tab
                  label={t('projects.comparison.textureTab')}
                  value={ComparisonTabs.TEXTURE}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={currentTabIndex}
              index={ComparisonTabs.FLAVOR_PROFILE}
            >
              <Box mt={2}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {selectedProducts.map((d, i) => (
                    <div
                      key={`GastrographRadar-${i}`}
                      style={{ width: `${COL_WIDTH}px`, flexShrink: '0' }}
                    >
                      {d.reportId && d.productId ? (
                        <GastrographRadar
                          title={getProductName({
                            productNames: customProductNames,
                            productId: d.productId,
                            version: d.version,
                          })}
                          productVersion={d.version}
                          titleCenter={true}
                          reportId={d.reportId}
                          productId={d.productId}
                          productDisplayName={getProductName({
                            productNames: customProductNames,
                            productId: d.productId,
                            version: d.version,
                          })}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              </Box>
            </TabPanel>
            <TabPanel
              value={currentTabIndex}
              index={ComparisonTabs.FLAVOR_DECOMPOSITION}
            >
              <Grid container>
                <Grid item xs={12}>
                  {selectedProducts[0]?.reportId &&
                  selectedProducts[1]?.reportId &&
                  selectedProducts[0]?.productId &&
                  selectedProducts[1]?.productId ? (
                    <FlavorDecompositionComparison
                      firstVersion={selectedProducts[0].version}
                      secondVersion={selectedProducts[1].version}
                      firstReportId={selectedProducts[0].reportId}
                      firstProductId={selectedProducts[0].productId}
                      firstProductDisplayName={getProductName({
                        productNames: customProductNames,
                        productId: selectedProducts[0].productId,
                        version: selectedProducts[0].version,
                      })}
                      secondReportId={selectedProducts[1].reportId}
                      secondProductId={selectedProducts[1].productId}
                      secondProductDisplayName={getProductName({
                        productNames: customProductNames,
                        productId: selectedProducts[1].productId,
                        version: selectedProducts[1].version,
                      })}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTabIndex}
              index={ComparisonTabs.PQ_DISTRIBUTION}
            >
              <Grid container>
                <Grid item xs={12} style={{ width: '100%' }}>
                  {hasCompleteSelections(selectedProducts) && (
                    <ProductMarketPreference
                      projectId={parseInt(projectId)}
                      customProductNames={customProductNames}
                      products={selectedProducts}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={currentTabIndex} index={ComparisonTabs.STACK_RANK}>
              <div style={{ display: 'flex', gap: '10px' }}>
                {selectedProducts.map(
                  (p, i) =>
                    p.reportId && (
                      <ReportSummaryComparison
                        key={`ReportSummaryComparison-${p.reportId}-${i}`}
                        projectId={parseInt(projectId)}
                        reportId={p.reportId}
                        hidePercentiles={true}
                      />
                    ),
                )}
              </div>
            </TabPanel>
            <TabPanel value={currentTabIndex} index={ComparisonTabs.MARKET_MAP}>
              <div style={{ display: 'flex', gap: '10px' }}>
                {selectedProducts.map(
                  (p, i) =>
                    p.reportId && (
                      <div
                        key={`ReportMarketMap-${p.reportId}-${i}`}
                        style={{ padding: 11 }}
                      >
                        <ReportMarketMap
                          projectId={parseInt(projectId)}
                          reportId={p.reportId}
                          parentReportId={null}
                          workspaceId={producerId}
                          userId={viewerUserId}
                          showTitle={false}
                          showLegend={false}
                          excludedProducts={new ProductVersionSet()}
                          setExcludedProducts={() => {}}
                          flavorMapOptions={p.mapOptions}
                          setFlavorMapOptions={(newOptions) =>
                            setSelectedProducts((current) =>
                              current.map((d, di) =>
                                di == i
                                  ? {
                                      ...d,
                                      mapOptions: newOptions,
                                    }
                                  : d,
                              ),
                            )
                          }
                        />
                      </div>
                    ),
                )}
              </div>
            </TabPanel>
            <TabPanel value={currentTabIndex} index={ComparisonTabs.TEXTURE}>
              <Box mt={2}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'stretch',
                  }}
                >
                  {selectedProducts.map((d, i) => (
                    <React.Fragment key={`ReportTextureMap-${d.reportId}-${i}`}>
                      <div style={{ padding: 11 }}>
                        <ReportTextureMap
                          projectId={parseInt(projectId)}
                          reportId={d.reportId ?? NULL_REPORT_ID}
                          parentReportId={null}
                          workspaceId={producerId}
                          showTitle={false}
                          showLegend={false}
                          textureMapOptions={d.mapOptions}
                          useTextureClusterName={true}
                          setTextureMapOptions={(newOptions) =>
                            setSelectedProducts((current) =>
                              current.map((d, di) =>
                                di == i ? { ...d, mapOptions: newOptions } : d,
                              ),
                            )
                          }
                          excludedProducts={new ProductVersionSet()}
                          setExcludedProducts={() => {}}
                        />
                      </div>
                      {i < selectedProducts.length - 1 && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            height: 'auto',
                            my: 2,
                            borderRightWidth: 2,
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </Box>
            </TabPanel>
          </Box>
        </div>
      </div>
    </Paper>
  );
};

export default ProjectComparison;
