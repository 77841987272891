import { ggVars } from 'constants/ggVars';

import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

import { useQuery } from '@apollo/client';
import GastrographRadarQuery from '@graphql/queries/GastrographRadarQuery';
import ReportFlavorStructureQuery from '@graphql/queries/ReportFlavorStructureQuery';
import { Grid, Box } from '@mui/material';
// Must be present for tree-shaking https://react-chartjs-2.js.org/docs/migration-to-v4/#tree-shaking
import type { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from 'chart.js';
import { chain } from 'lodash';
import { Radar } from 'react-chartjs-2';

import { toRadarChartData, getOFPs, getRawAndReshapeData } from './RadarUtils';
import ReportGastrographRadarCsv from './ReportGastrographRadarCsv';

ChartJS.register(
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

const getLabelIndex = (x: number, y: number, labels: any[]): number => {
  return labels
    .map((label, idx) => {
      const { bottom, top, left, right } = label;

      if (x >= left && x <= right && y >= top && y <= bottom) {
        return idx;
      } else {
        return -1;
      }
    })
    .reduce((accu: number, item: number) => (accu > -1 ? accu : item), -1);
};

const options = (
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  setMessage: React.Dispatch<React.SetStateAction<LabelMessage>>,
  componentWidth: number,
  pointLabelsFontColors: string[],
): ChartOptions<'radar'> => {
  return {
    onHover: ({ x, y }, activeHover, chart) => {
      const index = getLabelIndex(
        x,
        y,
        (chart.scales.r as any)._pointLabelItems,
      );
      if (index > -1) {
        const ggvar = chart.scales.r.getLabels()[index];
        const color = pointLabelsFontColors[index];
        setShow(true);
        setMessage({ label: ggvar, color: color });
      } else {
        setShow(false);
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return null;
          },
          label: function (context) {
            const pq = context.parsed.r;
            return context.label + ': ' + pq.toFixed(1);
          },
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0;
        },
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
          lineWidth: 1,
          color: 'black',
        },
        grid: {
          display: true,
          circular: false,
          color: 'black',
          lineWidth: 1.2,
          drawTicks: false,
        },
        min: 0,
        max: 5,
        beginAtZero: false,
        ticks: {
          maxTicksLimit: 6,
        },
        pointLabels: {
          color: (l) => pointLabelsFontColors[l.index],
          font: {
            size: 0.025 * componentWidth,
          },
        },
      },
    },
  };
};

interface ReportProps {
  reportId: string;
  productId: number;
  productVersion?: string;
  productDisplayName?: string;
  title: string;
  titleCenter: boolean;
}

interface LabelMessage {
  label: string;
  color: string;
}

const ReportGastrographRadar: React.FC<ReportProps> = (props) => {
  const {
    reportId,
    productId,
    productVersion,
    productDisplayName,
    title,
    titleCenter,
  } = props;

  const componentRef = useRef();

  const {
    loading: reportGastrographRadarLoading,
    error: reportGastrographRadarError,
    data: reportGastrographRadarResponse,
  } = useQuery<reports.ReportGastrographRadarReponse>(GastrographRadarQuery, {
    variables: {
      reportID: reportId,
      productID: productId,
    },
  });

  const {
    loading: reportFlavorStructureLoading,
    error: reportFlavorStructureError,
    data: reportFlavorStructureResponse,
  } = useQuery<reports.ReportFlavorStructureResponse>(
    ReportFlavorStructureQuery,
    {
      variables: {
        reportID: reportId,
        productID: productId,
      },
    },
  );

  const getComponentWidth = (ref) => ref?.current?.offsetWidth ?? 500;

  const getDimensions = (myRef) => ({
    width: myRef?.current?.offsetWidth ?? 500,
    height: myRef?.current?.offsetHeight ?? 500,
  });

  const [componentWidth, setComponentWidth] = useState<number>(
    getDimensions(componentRef).width,
  );

  useEffect(() => {
    const handleResize = () =>
      setComponentWidth(getDimensions(componentRef).width);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const [show, setShow] = useState<boolean>(false);
  const [labelMessage, setLabelMessage] = useState<LabelMessage>(null);

  const getLabelColorData = (
    reportFlavorStructureResponse: reports.ReportFlavorStructureResponse,
    productId: number,
  ): string[] => {
    const labelColorData = chain(
      reportFlavorStructureResponse.allRpFlavorStructures.nodes,
    )
      .filter(
        (value: reports.ReportFlavorStructure) =>
          value.label != null && value.product.id === productId,
      )
      .map((item: reports.ReportFlavorStructure) => ({
        color: item.nodeColor,
        label: item.label,
      }))
      .value();

    return ggVars.map((ggvar) => {
      if (labelColorData.map((d) => d.label).includes(ggvar.key)) {
        return labelColorData.filter((d) => d.label === ggvar.key)[0].color;
      } else {
        return 'black';
      }
    });
  };

  if (
    reportGastrographRadarLoading ||
    reportGastrographRadarError ||
    reportFlavorStructureLoading ||
    reportFlavorStructureError ||
    !componentRef
  ) {
    return <div />;
  }

  return (
    <Grid
      ref={componentRef}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11}>
        <Grid item xs={11} container direction="column" spacing={2}>
          <Grid item style={{ height: '40px', alignItems: 'flex-start' }}>
            <Box display="flex" justifyContent="center">
              <h5
                style={
                  titleCenter ? { textAlign: 'center', paddingRight: 5 } : {}
                }
              >
                {title}
              </h5>
              <ReportGastrographRadarCsv
                productName={productDisplayName}
                labels={
                  toRadarChartData(
                    getOFPs(reportGastrographRadarResponse, productVersion),
                  ).labels
                }
                values={getRawAndReshapeData(
                  reportGastrographRadarResponse,
                  productVersion,
                )}
              />
            </Box>
          </Grid>
          <Grid item>
            <Radar
              data={toRadarChartData(
                getOFPs(reportGastrographRadarResponse, productVersion),
              )}
              options={options(
                setShow,
                setLabelMessage,
                getComponentWidth(componentRef),
                getLabelColorData(reportFlavorStructureResponse, productId),
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        <Grid item xs={12}>
          <div
            style={{
              textAlign: 'center',
              visibility:
                show && labelMessage?.color !== 'black' ? 'visible' : 'hidden',
            }}
          >
            <span
              style={{
                color: labelMessage?.color,
              }}
            >
              {labelMessage?.label}
            </span>
            {labelMessage?.color === 'red'
              ? ': This flavor is perceived negatively in this product at the current intensity'
              : ': This flavor is perceived positively in this product at the current intensity'}
          </div>
        </Grid>
      }
    </Grid>
  );
};

export default ReportGastrographRadar;
