import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportTexturePreferenceQuery from '@graphql/queries/ReportTexturePreferenceQuery';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  HighlightableTableRow,
  hightlightedConditionalRowStyles,
} from 'components/ReactDataTable/highlightedRowStyles';
import { materialTableStyles } from 'components/ReactDataTable/materialTableStyles';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary from 'hooks/useReportSummary';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getReshapedData, numberOfClusters, selectCellValue } from './utils';
import { PROJECT_TEXTURE_SUMMARY_CHART } from '../../../../constants/routePaths';
import formatPath from '../../../../utils/formatPath';
import CSVDownload from '../../../CSVDownload';
import { ProductVersionSet } from '../../ProductVersion';
import { isProductHighlighted } from '../../utils';
import { truncateText } from '../ReportTextureComposition/utils';
import { formatCellValue } from '../textureUtils';

interface ReportProps {
  projectId?: number;
  reportId: string;
  useTextureClusterName?: boolean;
  highestClusterScoreId?: number;
}

export interface TexturePreferenceRow {
  name: string;
  productId: number;
  total: number;
  version: string;
  clusters: reports.ReportTexturePreference[];
}

const ReportTexturePreference: React.FC<ReportProps> = (props) => {
  const { projectId, reportId, useTextureClusterName, highestClusterScoreId } =
    props;

  const { t } = useTranslation();
  const customProductNames = useCustomProductNames({ projectId, reportId });

  const theme = useTheme();

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummaryData,
    productVersionSet,
  } = useReportSummary(reportId);

  const {
    data: tcNameData,
    loading: tcNameLoading,
    error: tcNameError,
  } = useQuery<reports.ReportTextureClusterNamesResponse>(
    textureClusterNamesQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: reportTexturePreferenceLoading,
    error: reportTexturePreferenceError,
    data: reportTexturePreferences,
  } = useQuery<reports.ReportTexturePreferencesResponse>(
    ReportTexturePreferenceQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    reportTexturePreferenceLoading ||
    reportTexturePreferenceError ||
    tcNameLoading ||
    tcNameError ||
    getReshapedData(
      reportTexturePreferences,
      productVersionSet,
      customProductNames,
    ).length === 0
  ) {
    return <div />;
  }

  const getTCNameByIndex = (
    tcNameData: reports.ReportTextureClusterNamesResponse,
    index: number,
  ) => {
    const clusterData =
      tcNameData?.allRpTextureClusterNames?.nodes?.find(
        (x) => x.clusterIdx === index,
      ) ?? null;
    return clusterData &&
      clusterData.customClusterName &&
      clusterData.customClusterName.length > 0
      ? truncateText(clusterData.customClusterName)
      : clusterData?.clusterName ?? '';
  };

  const getTCDisplayName = (
    tcNameData: reports.ReportTextureClusterNamesResponse,
    index: number,
    useTextureClusterName: boolean,
  ) =>
    useTextureClusterName
      ? getTCNameByIndex(tcNameData, index) ?? 'TC' + index
      : 'TC' + index;

  interface TableRowDt extends HighlightableTableRow {
    productDisplayName: string;
    preferenceTotal: string;
    productId: number;
    version: string;
    [x: string]: string | number | boolean;
  }

  const getClusterColumns = (
    reportTexturePreferences: reports.ReportTexturePreferencesResponse,
    highestClusterScoreId: number,
    useTextureClusterName: boolean,
    tcNameData: reports.ReportTextureClusterNamesResponse,
  ): TableColumn<TableRowDt>[] =>
    numberOfClusters(reportTexturePreferences).map((clusterId, _key) => ({
      name: (
        <div>
          {getTCDisplayName(tcNameData, clusterId, useTextureClusterName)}
        </div>
      ),
      selector: (row) => row[`TC${clusterId}`],
      width: '7%',
      compact: true,
      sortable: true,
      right: true,
      conditionalCellStyles: [
        {
          when: (row) => `TC${clusterId}` === `TC${highestClusterScoreId}`,
          style: {
            fontWeight: 'bold',
          },
        },
      ],
      cell: (row) => {
        const rowScore = +row[`TC${clusterId}`];
        const tooltipEffect = rowScore
          ? rowScore > 0
            ? `adds ${Math.abs(rowScore)} to`
            : `subtracts ${Math.abs(rowScore)} from`
          : `has a negligible effect on`;
        return (
          <Tooltip
            title={`${row.productDisplayName}'s ${getTCDisplayName(tcNameData, clusterId, useTextureClusterName)} ${tooltipEffect} its preference score.`}
          >
            <span>{rowScore.toString() == '--' || rowScore.toString() == 'NaN' ? '-' : rowScore}</span>
          </Tooltip>
        );
      },
    }));

  const getColumns: (
    reportTexturePreferences: reports.ReportTexturePreferencesResponse,
    highestClusterScoreId: number,
    useTextureClusterName: boolean,
    tcNameData: reports.ReportTextureClusterNamesResponse,
  ) => TableColumn<TableRowDt>[] = (
    reportTexturePreferences,
    highestClusterScoreId,
    useTextureClusterName,
    tcNameData,
  ) => [
    {
      name: 'Product',
      selector: (row) => row.productDisplayName,
      cell: (row) => (
        <Link
          to={{
            pathname: formatPath(PROJECT_TEXTURE_SUMMARY_CHART, {
              projectId,
              reportId,
              productId: row.productId,
              version: row.version,
            }),
          }}
        >
          {row.productDisplayName}
        </Link>
      ),
      sortable: true,
    },
    {
      id: 'preferenceTotal',
      name: 'Total',
      selector: (row) => row.preferenceTotal,
      width: '7%',
      compact: true,
      sortable: true,
      right: true,
    },
    ...getClusterColumns(
      reportTexturePreferences,
      highestClusterScoreId,
      useTextureClusterName,
      tcNameData,
    ),
  ];

  function getTableData(
    reportTexturePreferences,
    productVersionSet: ProductVersionSet,
    customProductNames: reports.ColorVersionedProductInfo[],
  ): TableRowDt[] {
    return getReshapedData(
      reportTexturePreferences,
      productVersionSet,
      customProductNames,
    ).map(
      (row, key): TableRowDt => ({
        productDisplayName: row.name,
        preferenceTotal: isNaN(row.total) ? '--' : row.total.toFixed(2),
        productId: row.productId,
        version: row.version,
        ...Object.fromEntries(
          numberOfClusters(reportTexturePreferences)
            .map((clusterIdx) => `TC${clusterIdx}`)
            .map((tcIdx) => [
              tcIdx,
              formatCellValue(selectCellValue(row, tcIdx)),
            ]),
        ),
        isHighlighted: isProductHighlighted({
          productNames: customProductNames,
          productId: row.productId,
          version: row.version,
        }),
      })
    );
  }

  function textureIndicesRow(reportTexturePreferences, tcNameData) {
    return {
      Product: '',
      Total: null,
      ...Object.fromEntries(
        numberOfClusters(reportTexturePreferences).map((n) => [
          getTCNameByIndex(tcNameData, n) ?? `TC${n}`,
          `TC${n}`,
        ]),
      ),
    };
  }

  return (
    <div>
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Typography variant="h5">
              {t('reports.texturePreference.title')}
            </Typography>
            <Tooltip title={t('reports.texturePreference.explainer')}>
              <IconButton size="large">
                <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <CSVDownload
            fileName="Texture_Preference.csv"
            headers={[
              t('reports.texturePreference.product'),
              t('reports.texturePreference.total'),
            ]
              .concat(
                numberOfClusters(reportTexturePreferences).map((clusterId) =>
                  useTextureClusterName
                    ? getTCNameByIndex(tcNameData, clusterId) ??
                      `TC${clusterId}`
                    : `TC${clusterId}`,
                ),
              )
              .map((d) => ({ label: d, key: d }))}
            csvData={[
              textureIndicesRow(reportTexturePreferences, tcNameData),
            ].concat(
              getReshapedData(
                reportTexturePreferences,
                productVersionSet,
                customProductNames,
              ).map((d) => {
                const re = {
                  Product: d.name,
                  Total: isNaN(d.total) ? null : d.total,
                };
                numberOfClusters(reportTexturePreferences).forEach((n) => {
                  if (useTextureClusterName) {
                    Object.assign(re, {
                      [getTCNameByIndex(tcNameData, n) ?? `TC${n}`]:
                        d.clusters[n - 1].pqImpact,
                    });
                  } else {
                    Object.assign(re, {
                      [`TC${n}`]: d.clusters[n - 1].pqImpact,
                    });
                  }
                });
                return re;
              }),
            )}
          />
        </Grid>
      </Grid>
      <DataTable
        columns={getColumns(
          reportTexturePreferences,
          highestClusterScoreId,
          useTextureClusterName,
          tcNameData,
        )}
        data={getTableData(
          reportTexturePreferences,
          productVersionSet,
          customProductNames,
        )}
        defaultSortFieldId={'preferenceTotal'}
        defaultSortAsc={false}
        customStyles={materialTableStyles(theme)}
        noDataComponent={() => <div />}
        conditionalRowStyles={hightlightedConditionalRowStyles}
      />
    </div>
  );
};

export default ReportTexturePreference;
