import * as React from 'react';
import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';
import TextureMapLdaQuery from '@graphql/queries/TextureMapLdaQuery';
import TextureProductsQuery from '@graphql/queries/TextureMapProductsQuery';
import TextureVectorsQuery from '@graphql/queries/TextureMapVectorsQuery';
import LoadingScreen from 'components/LoadingScreen';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import { TextureCluster } from 'components/Report/Texture/ReportTextureCluster/textureClusterUtils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useGgEnvironment from 'hooks/useGgEnvironment';
import useReportSummary from 'hooks/useReportSummary';
import { chain } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCustomerPreferences } from 'services/customerPreferences';

import InsufficientDataMap from './InsufficentDataMap';
import MarketMap from './MarketMap';
import { getProductLabel, getProductName } from '../../utils';
import { MapOptions, setRotationAngle } from '../MapOptions';
import { mapLabelVersion } from '../mapUtils';

interface ReportProps {
  projectId?: number;
  reportId: string;
  showLegend: boolean;
  showTitle: boolean;
  excludedProducts: ProductVersionSet;
  textureMapOptions: MapOptions;
  setTextureMapOptions: (newMapOptions: MapOptions) => void;
  pageUpdated?: boolean;
  viewerUserId?: number;
  workspaceId?: number;
  parentReportId?: string;
  useTextureClusterName?: boolean;
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>;
  groupedTextureClusters?: TextureCluster[];
}

const ReportTextureMap: React.FC<ReportProps> = (props) => {
  const {
    showTitle,
    showLegend,
    projectId,
    reportId,
    excludedProducts,
    textureMapOptions,
    setTextureMapOptions,
    pageUpdated,
    viewerUserId,
    workspaceId,
    parentReportId,
    useTextureClusterName,
    setExcludedProducts,
    groupedTextureClusters,
  } = props;

  const { t } = useTranslation();

  const customProductNames = useCustomProductNames({
    projectId,
    refetchNames: pageUpdated,
  });
  const environment = useGgEnvironment();

  const customerPreferences = useCustomerPreferences();

  const {
    loading: summaryLoading,
    error: summaryError,
    data: reportSummary,
    productVersionSet,
  } = useReportSummary(reportId);

  const {
    loading: productsLoading,
    error: productsError,
    data: textureMapProductsResponse,
  } = useQuery<reports.ReportTextureMapProductsResponse>(TextureProductsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: tcloading,
    error: tcerror,
    data: tcVectorsResponse,
  } = useQuery<reports.ReportTextureMapVectorsResponse>(TextureVectorsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    data: tcNameData,
    loading: tcNameLoading,
    error: tcNameError,
  } = useQuery<reports.ReportTextureClusterNamesResponse>(
    textureClusterNamesQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: ldaLoading,
    error: ldaerror,
    data: textureMapLdaResponse,
  } = useQuery<reports.ReportTextureMapLdaResponse>(TextureMapLdaQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: parentSummaryLoading,
    error: parentSummaryError,
    data: parentReportSummary,
    productVersionSet: parentProductVersionSet,
    productVersionPqs: parentProductVersionPqs,
  } = useReportSummary(parentReportId, {
    skip: !parentReportId,
  });

  const {
    loading: parentProductsLoading,
    error: parentProductsError,
    data: parentProductsResponse,
  } = useQuery<reports.ReportTextureMapProductsResponse>(TextureProductsQuery, {
    variables: {
      reportID: parentReportId,
    },
    skip: !parentReportId,
  });

  useEffect(() => {
    if (tcVectorsResponse && textureMapOptions.rotationPristine) {
      setTextureMapOptions(
        setRotationAngle(
          textureMapOptions,
          0, //rotateToDiagonal(reshapeTCVectorArray(tcVectorsResponse)),
        ),
      );
    }
  }, [tcVectorsResponse]);

  const detemineProductRMax = (
    textureMapProductsResponse: reports.ReportTextureMapProductsResponse,
    productVersionSet: ProductVersionSet,
  ) => {
    return textureMapProductsResponse?.allRpTexturemapProductvectors?.nodes
      .filter((row) =>
        productVersionSet.has(new ProductVersion(row.productId, row.version)),
      )
      .map((v) => Math.sqrt(Number(v.ld1) ** 2 + Number(v.ld2) ** 2))
      .reduce(
        (acc: number, val: number) =>
          acc === undefined || val > acc ? val : acc,
        undefined,
      );
  };

  const getTCDataByIndex = (index: number) =>
    tcNameData?.allRpTextureClusterNames?.nodes.find(
      (x) => x.clusterIdx === index,
    );

  const reshapeTCVectorArray = (
    tcVectorsResponse: reports.ReportTextureMapVectorsResponse,
  ): marketmap.LabelData[] => {
    return chain(tcVectorsResponse?.allRpTexturemapTcvectors?.nodes)
      .groupBy('varnames')
      .map((item) => {
        const tcData = getTCDataByIndex(Number(item[0].varnames.charAt(2)));
        const displayName =
          tcData?.customClusterName ??
          tcData?.clusterName ??
          item[0].varnames.replace('_score', '');
        return {
          label:
            useTextureClusterName && tcData
              ? displayName
              : item[0].varnames.replace('_score', ''),
          angle: +item[0].angle,
          ld1: +item[0].ld1,
          ld2: +item[0].ld2,
        };
      })
      .value();
  };

  const reshapeHeatMapArray = (
    textureMapProductsResponse: reports.ReportTextureMapProductsResponse,
  ): marketmap.HeatMapData[] => {
    return (
      textureMapProductsResponse?.allRpTexturemapProductvectors?.nodes
        .map((v: reports.ReportTextureMapProductRow) => ({
          pixelId: null,
          productId: null,
          ld1: v.ld1,
          ld2: v.ld2,
          pq: v.pq,
          r: null,
          ld1r: null,
          ld2r: null,
        }))
        // Drop the null PQ rows
        .filter((r) => !!r.pq)
    );
  };

  const reshapeContourArray = (
    textureMapProductsResponse: reports.ReportTextureMapProductsResponse,
    productVersionSet: ProductVersionSet,
  ): marketmap.ContourMapData[] => {
    return textureMapProductsResponse?.allRpTexturemapProductvectors?.nodes
      .filter((row) =>
        productVersionSet.has(new ProductVersion(row.productId, row.version)),
      )
      .map((v: reports.ReportTextureMapProductRow) => ({
        pixelId: null,
        productId: null,
        ld1: v.ld1,
        ld2: v.ld2,
        pq: v.pq,
        r: null,
        ld1r: null,
        ld2r: null,
      }));
  };

  const reshapeTextureMapLdaArray = (
    textureMapLdaResponse: reports.ReportTextureMapLdaResponse,
  ): marketmap.HeatMapData[] => {
    return textureMapLdaResponse?.allRpTexturemapLdas?.nodes.map(
      (v: reports.ReportTextureMapProductRow) => ({
        pixelId: null,
        productId: null,
        ld1: v.ld1,
        ld2: v.ld2,
        pq: v.pq,
        r: null,
        ld1r: null,
        ld2r: null,
      }),
    );
  };

  const reshapeProductVectorArray = (
    textureMapProductsResponse: reports.ReportTextureMapProductsResponse,
    productVersionSet: ProductVersionSet,
  ): marketmap.ProductVectorData[] => {
    return chain(
      textureMapProductsResponse?.allRpTexturemapProductvectors?.nodes,
    )
      .filter((n: reports.ReportTextureMapProductRow) =>
        productVersionSet.has(new ProductVersion(n.productId, n.version)),
      )
      .groupBy((n) => `${n.productId}-${n.version}`)
      .map((value, key) => ({
        productId: value[0].productId,
        productVersion: new ProductVersion(
          value[0].productId,
          value[0].version,
        ),
        name: getProductName({
          productNames: customProductNames,
          productId: value[0].productId,
          version: value[0].version,
        }),
        version: value[0].version,
        ld1: +value[0].ld1,
        ld2: +value[0].ld2,
        pq: +value[0].pq,
        idx: productVersionSet.findIndex(
          new ProductVersion(value[0].productId, value[0].version),
        ),
        label:
          getProductLabel({
            productNames: customProductNames,
            productId: value[0].productId,
            version: value[0].version,
          }) ||
          mapLabelVersion(
            customerPreferences,
            productVersionSet,
            new ProductVersion(value[0].productId, value[0].version),
          ),
        productLabelColor: customProductNames.filter(
          (d) => d.id == value[0].productId && d.version == value[0].version,
        )[0]?.productLabelColor,
      }))
      .value();
  };

  const isInArray = (
    acc: marketmap.ProductVectorData[],
    cur: marketmap.ProductVectorData,
  ) => {
    if (acc.length) {
      return acc
        .map((i) => i.productId == cur.productId && i.version === cur.version)
        .reduce((a, b) => a || b);
    } else {
      return false;
    }
  };

  if (
    summaryLoading ||
    summaryError ||
    productsLoading ||
    productsError ||
    tcloading ||
    tcerror ||
    tcNameLoading ||
    tcNameError ||
    ldaLoading ||
    ldaerror ||
    (parentReportId &&
      (parentProductsLoading ||
        parentProductsError ||
        parentSummaryLoading ||
        parentSummaryError))
  ) {
    return <LoadingScreen circular={true} />;
  }

  const productRMax = parentProductsResponse
    ? Math.max(
        detemineProductRMax(textureMapProductsResponse, productVersionSet),
        detemineProductRMax(parentProductsResponse, parentProductVersionSet),
      ) * Math.sqrt(2)
    : detemineProductRMax(textureMapProductsResponse, productVersionSet) *
      Math.sqrt(2);

  const productVectorArray = reshapeProductVectorArray(
    textureMapProductsResponse,
    productVersionSet,
  )
    .concat(
      parentProductsResponse
        ? reshapeProductVectorArray(
            textureMapProductsResponse,
            parentProductVersionSet,
          )
        : [],
    )
    // Filter out duplicates
    .reduce(
      (acc: marketmap.ProductVectorData[], cur: marketmap.ProductVectorData) =>
        isInArray(acc, cur) ? acc : acc.concat(cur),
      [],
    );
  const ldasArray = reshapeTextureMapLdaArray(textureMapLdaResponse);
  const heatmapArray = reshapeHeatMapArray(textureMapProductsResponse);
  const contourArray = reshapeContourArray(
    textureMapProductsResponse,
    productVersionSet,
  ).concat(
    parentProductsResponse
      ? reshapeContourArray(textureMapProductsResponse, parentProductVersionSet)
      : [],
  );
  const tcvectors = reshapeTCVectorArray(tcVectorsResponse).filter((vector) => {
    if (!groupedTextureClusters) {
      return true;
    }
    const vectorclusters = groupedTextureClusters.filter(
      (c) => !!c[vector.label],
    );
    if (vectorclusters.length == 0) {
      return true;
    }
    return vectorclusters[0][vector.label].length > 0;
  });

  if (productVectorArray.length == 0 || heatmapArray.length == 0) {
    return <InsufficientDataMap />;
  } else {
    return (
      <div>
        {showTitle && <h4>{t('reports.marketMap.title')}</h4>}
        <MarketMap
          isProd={environment === 'production'}
          productRMax={productRMax}
          productVectorArray={productVectorArray}
          ggVarArray={tcvectors}
          refFlavorArray={[]}
          heatmapArray={ldasArray.concat(heatmapArray)}
          contourArray={contourArray}
          excludedProducts={excludedProducts}
          projectId={projectId}
          reportId={reportId}
          viewerUserId={viewerUserId}
          workspaceId={workspaceId}
          allowPixelRequest={false}
          granularity={800}
          contourThreshold={6}
          contourBandwidth={60}
          mapOptions={textureMapOptions}
          setMapOptions={setTextureMapOptions}
          setExcludedProducts={setExcludedProducts}
        />
      </div>
    );
  }
};

export default ReportTextureMap;
