import * as d3 from 'd3';

import * as d3hb from 'd3-hexbin';

import './layers.tooltip.css';
import CreatePixelRequestMutation from '@graphql/mutations/CreatePixelRequest';
import NotifyPixelRequest from '@graphql/mutations/NotifyPixelRequest';
import graphqlClient from 'consumers/graphqlClient';
import moment from 'moment';
import {
  CustomerPreferences,
  heatMapColors,
  heatMapShape,
  selectHexRadius,
  selectRectEdge,
} from 'services/customerPreferences';

import {
  maxPq,
  meanPq,
  minPq,
  computePixelId,
  rotateScaled,
  heatmapHexFilter,
  heatmapRectFilter,
  meanPqProducts,
} from './layerUtils';
import rectbin from '../RectBin/rectbin';

const d3cm = require('d3-context-menu');

const notifyPixelAnalysisRequest = async (
  userId: number,
  pixelId: string,
  projectId: number,
  workspaceId: number,
  reportId: string,
  pixelPq: number,
  createdAt: moment.Moment,
) => {
  await graphqlClient.mutate({
    mutation: NotifyPixelRequest,
    variables: {
      userId: userId,
      pixelId: pixelId,
      projectId: projectId,
      workspaceId: workspaceId,
      reportId: reportId,
      pixelPq: pixelPq,
      createdAt: createdAt,
    },
  });
};

const createPixelAnalysisRequest = async (
  userId: number,
  pixelId: string,
  projectId: number,
  workspaceId: number,
  reportId: string,
  pixelPq: number,
  createdAt: moment.Moment,
) => {
  await graphqlClient.mutate({
    mutation: CreatePixelRequestMutation,
    variables: {
      pixelRequest: {
        userId: userId,
        pixelId: pixelId,
        projectId: projectId,
        workspaceId: workspaceId,
        reportId: reportId,
        pixelPq: pixelPq,
        createdAt: createdAt,
      },
    },
  });
};

export interface MarginalEffects {
  r: number;
  ld1r: number;
  ld2r: number;
  pixelId: number;
  productId: number;
  pq: number;
  ld1: number;
  ld2: number;
}

// Heat Map Layer
// This renders the heat map behind the map
export function heatMapLayer(
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  zoom: number,
  rotationAngle: number,
  heatmapTransparency: number,
  tx: number,
  ty: number,
  productRMax: number,
  heatmapArray: marketmap.HeatMapData[],
  allowPixelRequest: boolean,
  customerPreferences: CustomerPreferences,
  workspaceId?: number,
  userId?: number,
  projectId?: number,
  reportId?: string,
  isSuperAdmin?: boolean,
) {
  if (heatmapArray.length == 0) {
    return;
  }

  const marginaleffects: MarginalEffects[] = rotateScaled(
    heatmapArray,
    rotationAngle,
    productRMax / zoom,
  );

  const meanpq = meanPq(marginaleffects);
  const maxpq = maxPq(marginaleffects);
  const minpq = minPq(marginaleffects);

  const colors = heatMapColors(
    customerPreferences,
    heatmapTransparency,
    minpq,
    meanpq,
    maxpq,
  );

  const tip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0);

  const menu = [
    {
      title: 'Request Pixel Analysis',
      action: function (d, event) {
        const data = (event.target.__data__ as marketmap.HeatMapData[]) || [];
        const pixelId = computePixelId(data);
        const pixelPq = meanPq(data);

        notifyPixelAnalysisRequest(
          userId,
          pixelId,
          projectId,
          workspaceId,
          reportId,
          pixelPq,
          moment(),
        );
        createPixelAnalysisRequest(
          userId,
          pixelId,
          projectId,
          workspaceId,
          reportId,
          pixelPq,
          moment(),
        );

        myModal.style('display', 'inline');
        event.preventDefault();
      },
    },
  ];

  const myModal = d3
    .select('body')
    .append('div')
    .attr('class', 'cust-modal')
    .attr('class', 'cust-modal')
    .on('click', function (d) {
      myModal.style('display', 'none');
    });

  const myModalContent = myModal
    .append('div')
    .attr('class', 'cust-modal-content');
  const myModalTitle = myModalContent.append('p').text('Thank you!');
  const myModalMessage = myModalContent
    .append('p')
    .text('Sales team will contact you about this request');

  const mouseOverFunc = function (d) {
    const productNamesDiv =
      isSuperAdmin && d.metaKey
        ? '<div class="pqtextTop">' +
          meanPqProducts(d.target.__data__) +
          '</div>'
        : '';

    tip
      .style('opacity', 1)
      .html(
        '<div class="pqmain">' +
          '<div class="pq">' +
          '<div class="pqtext">' +
          meanPq(d.target.__data__).toFixed(1) +
          '</div>' +
          '<div class="pqcircle" style="background-color: ' +
          colors(meanPq(d.target.__data__) - meanpq) +
          ';"> </div>' +
          '</div>' +
          productNamesDiv +
          '</div>',
      )
      .style('left', d.pageX - 25 + 'px')
      .style('top', d.pageY - 75 + 'px');
  };

  container.select('#heatMapLayer').remove();

  // Render HEX or RECT map
  if (heatMapShape(customerPreferences) === 'rect') {
    const rectEdge = selectRectEdge(customerPreferences, zoom);

    const rectBin = rectbin<marketmap.HeatMapData>()
      .x((d) => r * d.ld1r)
      .y((d) => -r * d.ld2r)
      .dx((d) => rectEdge)
      .dy((d) => rectEdge);
    marginaleffects;
    const rectbinData = rectBin(marginaleffects);

    container
      .append('g')
      .attr('id', 'heatMapLayer')
      .selectAll('path')
      .data(
        rectbinData
          .filter((i) => i.length > 0)
          .filter((d) =>
            heatmapRectFilter(customerPreferences, d, r, zoom, rectEdge),
          ),
      )
      .enter()
      .append('rect')
      .attr('width', rectEdge)
      .attr('height', rectEdge)
      .attr('ld1', (d) => (d.x + rectEdge / 2) * (productRMax / (r * zoom)))
      .attr('ld2', (d) => -(d.y + rectEdge / 2) * (productRMax / (r * zoom)))
      .attr('transform', (d) => `translate(${d.x - tx},${d.y - ty})`)
      .attr('fill', (d) => colors(meanPq(d) - meanpq))
      .on('mouseover', (d) => mouseOverFunc(d))
      .on('mouseleave', (d) => tip.style('opacity', 0));
  } else {
    const hexRadius = selectHexRadius(customerPreferences, zoom);
    const hexbin = d3hb
      .hexbin<marketmap.HeatMapData>()
      .x((d) => r * d.ld1r)
      .y((d) => -r * d.ld2r)
      .radius(hexRadius);
    const bins: d3hb.HexbinBin<marketmap.HeatMapData>[] =
      hexbin(marginaleffects);

    container
      .append('g')
      .attr('id', 'heatMapLayer')
      .selectAll('path')
      .data(
        bins.filter((d) => heatmapHexFilter(customerPreferences, d, r, zoom)),
      )
      .join('path')
      .attr('d', hexbin.hexagon())
      .attr('ld1', (d) => (d.x + hexRadius) * (productRMax / (r * zoom)))
      .attr('ld2', (d) => -(d.y + hexRadius) * (productRMax / (r * zoom)))
      .attr('transform', (d) => `translate(${d.x - tx},${d.y - ty})`)
      .attr('fill', (d) => colors(meanPq(d) - meanpq))
      .style('fill-opacity', '0.8')
      .on('mouseover', (d) => mouseOverFunc(d))
      .on('mouseleave', (d) => tip.style('opacity', 0));
  }

  if (allowPixelRequest) {
    container.on('contextmenu', d3cm(menu));
  }
}
