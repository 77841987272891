import * as React from 'react';

import { useTheme } from '@mui/material';
import { Box } from '@mui/material';
import { getProductName } from 'components/Report/utils';
import { useTranslation } from 'react-i18next';
import { cleanUnderscore } from 'utils/afsUtils';

import useStyles from '../useStyles';

interface Props {
  nodes: reports.OrderOfOperationNode[];
  productNames: reports.ColorVersionedProductInfo[];
  isTop?: boolean;
}

const ProductTop3Steps: React.FC<Props> = (props) => {
  const { nodes, productNames, isTop } = props;

  const { t } = useTranslation();
  const classes = useStyles(useTheme());

  if (!nodes || nodes.length == 0) {
    return <div />;
  }

  return (
    <div>
      <Box sx={{ paddingBottom: 1 }}>
        {isTop
          ? '🥇 ' + t('reports.optimizationTopThree.topChanges')
          : t('reports.optimizationTopThree.topChangesAlt')}
        <span className={classes.mainText}>
          {getProductName({
            productNames,
            productId: nodes[0].productId,
            version: nodes[0].version,
          })}
        </span>
        {`:`}
      </Box>
      <ul>
        {nodes.map((node) => {
          const decomp = parseFloat(node.deltaDecomp);

          if (isNaN(decomp)) {
            const signature = parseFloat(node.deltaSignature);
            if (signature > 0) {
              return (
                <li>
                  {t('reports.optimizationTopThree.increasingDecompUnavailable', {
                    flavor: cleanUnderscore(node.refFlavor),
                  })}
                </li>
              );
            } else {
              return (
                <li>
                  {t('reports.optimizationTopThree.decreasingDecompUnavailable', {
                    flavor: cleanUnderscore(node.refFlavor),
                  })}
                </li>
              );
            }
          }
          if (decomp > 0) {
            return (
              <li>
                {t('reports.optimizationTopThree.increasingFlavor', {
                  flavor: cleanUnderscore(node.refFlavor),
                  decomp: Math.abs(decomp * 100).toPrecision(2),
                })}
              </li>
            );
          } else {
            return (
              <li>
                {t('reports.optimizationTopThree.decreasingFlavor', {
                  flavor: cleanUnderscore(node.refFlavor),
                  decomp: Math.abs(decomp * 100).toPrecision(2),
                })}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default ProductTop3Steps;
